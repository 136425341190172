/*
Copyright 2022 Watcha
Copyright 2023 Avencall

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

.xivo_NextcloudShareDialog {
    height: 80vh;
    display: flex;
    flex-direction: column;

    .mx_Dialog_content {
        flex-grow: 1;
    }
    iframe {
        width: 100%;
        height: 100%;
        border: 0;
        border-bottom: 1px solid black;
    }
    .mx_Field {
        flex-grow: 0 !important;
    }
}

.xivo_NextcloudShareDialog_iframe-hidden {
    display: none;
}

.xivo_NextcloudShareDialog_Field_rootSelection {
    input {
        color: red !important;
    }
}
